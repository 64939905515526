import router from "@/router";
import * as Sentry from "@sentry/vue";

export default {
    response: [
        /**
         * Checks if response status is unauthorized and redirect if applies
         */
        (res) => {
            if (res.status === 401 || res.status === 403) {
                if (!router.currentRoute.value.path.includes("/welkom")) {
                    Sentry.addBreadcrumb({
                        category: "401",
                        message: "Was redirect to welcome page",
                        level: Sentry.Severity.Info,
                    });
                    router.push("/welkom");
                }
            }

            return res;
        },
    ],
};
